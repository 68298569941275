import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { css } from '@emotion/core';
import _ from 'lodash';
import { Row, Col } from 'shards-react';
import { useMediaQuery } from 'react-responsive';
import MetaTags from 'react-meta-tags';

import images from '../../images/images';
import { TopScrollContainer } from '../../components/custom-list/index';
import MemorabiliaTimeline from '../../components/custom-list/MemorabiliaTimeline';

import useMemorabilia from '../../api/memorabilia-service';

const AboutPostpartum = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const isSmallMobile = useMediaQuery({ query: '(max-width: 647px)' });

  const [state, produce] = useImmer({
    ...location.state,
    loading: true,
    hasData: false,
    memorabilias: null,
  });

  const { loading, hasData, memorabilias } = state;

  const CoreSpiritDatas = [
    {
      image: images.about_04_a_01,
      title: '專業',
      content: '醫療顧問團隊，成為媽咪堅強後盾',
    },
    {
      image: images.about_04_a_02,
      title: '貼心',
      content: '舒適體驗，享受孕期產後時光',
    },
  ];
  const FiveCharDatas = [
    {
      image: [images.about_04_a_03, images.about_04_a_04],
      title: '醫學中心級醫師陣容',
      items: [
        {
          title: '專業陣容',
          items: [ 
            '婦產科、小兒科醫師陣容',
            '入房巡診每週3次',
            '完善後送機制'
          ]
        }    
      ],
    },
    {
      image: [images.about_04_a_05],
      title: '全資深護理師照護',
      items: [
        {
          title: '嬰兒照護',
          items: [ 
            '資深嬰兒照護經驗',
            'NRP新生兒急救術',
            '照護9000+位寶寶'
          ]
        },
        {
          title: '照護指導',
          items: [ 
            '指導媽咪育兒知識',
            '輔導爸爸變身神隊友',
          ]
        }   
      ],
    },
    {
      image: [images.about_04_a_06],
      title: '一對一營養師諮詢',
      items: [
        {
          title: '營養諮詢',
          items: [ 
            '駐點專職營養師',
            '醫學中心身組分析',
            '個人專屬諮詢'
          ]
        },
        {
          title: '體態恢復',
          items: [ 
            '階段式進補菜單',
            '產後體態恢復',
            '出住變小姐'
          ]
        },
      ],
    },
    {
      image: [images.about_04_a_07],
      title: '高規格坐月子中心',
      items: [
        {
          title: '感染控制',
          items: [ 
            '高規格感控',
            '環保局空氣品質認證',
          ]
        },
        {
          title: '現煮餐點',
          items: [ 
            '自設廚房高規把關',
            '餐飲分級標章認證',
          ]
        },
      ],
    },
    {
      image: [images.about_04_a_08, images.about_04_a_09],
      title: '',
      items: [
        {
          title: '舒適空間',
          items: [ 
            '友善大寶環境',
            '嬰兒室全程透明照護',
          ]
        },
      ],
    },
    {
      image: [images.about_04_a_10],
      title: '全方位孕期產後服務',
      items: [
        {
          title: '寶寶APP',
          items: [ 
            '一對一寶寶視訊',
            '視覺紀錄媽寶點滴',
          ]
        },  
      ],
    },
    {
      image: [images.about_04_a_11, images.about_04_a_12],
      title: '',
      items: [
        {
          title: '放鬆休閒',
          items: [ 
            'SPA按摩舒壓',
            '產後瑜珈伸展',
          ]
        },
        {
          title: '多元課程',
          items: [ 
            '育兒知識講座',
            '媽咪身心紓壓教室',
            '打造爸爸神隊友速成班',
          ]
        },  
      ],
    },
  ];

  const { getMemorabilia } = useMemorabilia();

  useEffect(() => {
    getMemorabilias();
  }, []);

  const getMemorabilias = async () => {
    produce((draft) => void (draft.loading = true));
    const { status, message, result } = await getMemorabilia();
    produce((draft) => void (draft.loading = false));
    if (status == '1') {
      produce((draft) => void (draft.memorabilias = result));
      if (result.length != 0) {
        produce((draft) => void (draft.hasData = true));
      }
    }
  };

  return (
    <TopScrollContainer
      breadcrumb={[{ title: '首頁', to: '/' }, { title: '關於國泰產後' }]}
      titleBackground={images.top_04_a}
      background={images.background_04_a}
      title={'關於國泰產後'}
      css={styles}
      customPadding={true}
      // titleBtn={['核心精神', '五大特色', '大事紀']}
    >
      <MetaTags>
        <title>關於國泰產後｜國泰產後核心精神｜從孕期到產後貼心專業母嬰照護</title>
        <meta
          name="description"
          content="國泰產後護理之家提供全護理專業照護，守護媽媽與寶寶健康，協助新手家庭維持生活節奏，無痛銜接返家生活。有別於業界的專屬營養諮詢服務，協助媽媽體態恢復。"
        />
        <meta name="keywords" content="國泰,國泰產後,國泰產後護理之家,產後護理之家,產後護理,坐月子,月子中心" />
      </MetaTags>
      <h3 id="item0" style={{ fontSize: 24, fontWeight: 500, color: '#5e5e5e', textAlign: 'center' }}>
        核心精神
      </h3>
      <Row style={{ display: 'flex', width: '100%', flexDirection: isMobile ? 'column' : '' }}>
        {_.map(CoreSpiritDatas, (item, index) => (
          <Col key={index} className={isMobile ? 'mb-3 mx-3' : 'mx-3' } style={{ flex: 1, display: 'flex', flexDirection: 'column'}}>
            <img
              src={item.image}
              style={{
                width: isMobile ? '100%' : '85%',
                maxWidth: 450,
                minWidth: 320,
                height: 'fit-content',
                alignSelf: 'center',
                objectFit: 'cover',
                borderRadius: 6,
              }}
            />
            <span
              style={{
                marginTop: 10,
                fontSize: 26,
                color: '#AE8D60',
                textAlign: 'center',
                fontWeight: 400,
                whiteSpace: 'pre-line',
              }}>
              {item.title}
              <span
                style={{
                  marginTop: 10,
                  marginLeft: 15,
                  fontSize: 18,
                  color: '#5E5E5E',
                  textAlign: 'center',
                  fontWeight: 400,
                  whiteSpace: 'pre-line',
                }}
              >
                {item.content}
              </span>
            </span>
          </Col>
        ))}
      </Row>
      <Row
        className={isMobile ? 'mx-3' : ''}
        style={{
          marginTop: 20,
          background: '#C5C1BA',
          height: 1,
          marginRight: 0,
        }}
      />

      <h3 id="item1" style={{ marginTop: 19, fontSize: 24, fontWeight: 500, color: '#5e5e5e', textAlign: 'center' }}>
        五大特色
      </h3>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {_.map(FiveCharDatas, (data, index) => (
          <Row
            key={index}
            className={isMobile ? '' : 'my-4'}
            style={{ width: '100%', flex: 1, flexDirection: index % 2 == 1 ? 'row-reverse' : 'row' }}
          >
            {_.map(data.image, (img, i) => (
              <img
              // className="mr-2"
              src={img}
              style={{
                width: isMobile ? 'calc(100% - 30px)' : index == 4 && i == 1  ? '42%' : index == 5 ? '70%' : `calc(100% / (${data.image.length} + 1 ))`,
                height: isMobile ? 260 : 'fit-content',
                alignSelf: 'center',
                maxHeight: 260,
                maxWidth: index == 5 ? 616 : 560,
                minWidth: isMobile ? 120 : '',
                marginLeft: isMobile ? 45 : 20,
                marginInline: isSmallMobile ? 'auto 0' : isMobile ? 'auto' : '',
                objectFit: isMobile ? 'cover' : '',
                objectPosition : isMobile && index == 5 ? 'right' : '',
                borderRadius: 6,
                marginBottom: isMobile ? 20 : '',
              }}
            />
            ))}
            
            <Col       
                className={`ml-4 ${index % 2 == 1 ? 'fiveCharOddItems' : 'fiveCharEvenItems'}`} 
                xs={isMobile && 12} md={isMobile && 12}>
                <Row>
                  <span style={{color: '#5E5E5E', fontSize: 26}}>{data?.title}</span>
                </Row>
                <Row className="mt-3">
                {_.map(data.items, (item, idx) => (
                <Col className="px-0" md={6} lg={ index > 4 || data.items.length == 1 ? 12 : 6}>
                <label style={{color: '#AE8D60', fontSize: 26, height: 36}}> 
                  <img src={images.line_icon} style={{verticalAlign: 'top', marginRight: 8, paddingTop: 5}}/>
                  {item.title}
                </label>
                <ul className="my-3 pl-3">
                  {_.map(item.items, (item, ind) => (
                    <li
                      key={index}
                      style={{
                        fontSize: 16,
                        color: '#5E5E5E',
                        textAlign: 'justify',
                        fontWeight: 400,
                        marginBottom: 10,
                        paddingLeft: 0,
                        listStyleImage: `url(${images.tree_icon})`,
                      }}
                    >
                      {item}
                    </li>))}   
                  </ul>
                </Col>))}
              </Row>
            </Col>
          </Row>
        ))}
      </div>
      <Row
        style={{
          marginTop: 20,
          background: '#C5C1BA',
          height: 1,
          marginRight: 0,
        }}
      />

      <h3 id="item2" style={{ marginTop: 19, fontSize: 24, fontWeight: 500, color: '#5e5e5e', textAlign: 'center' }}>
        大事紀
      </h3>
      {hasData && <MemorabiliaTimeline datas={memorabilias} />}
    </TopScrollContainer>
  );
};

export default AboutPostpartum;

const styles = css``;
