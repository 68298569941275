import React, { useEffect, useRef } from 'react';
import { Button, Row, Col, DatePicker, Form, FormInput, FormFeedback } from 'shards-react';
import { useForm } from 'react-hook-form';
import { useImmer } from 'use-immer';
import { css } from '@emotion/core';
import Select from 'react-select';
import moment from 'moment';
import { registerLocale } from 'react-datepicker';
import zh from 'date-fns/locale/zh-TW';
registerLocale('zh', zh);
import { BounceLoader } from 'react-spinners';
import ReactShadowScroll from 'react-shadow-scroll';
import MetaTags from 'react-meta-tags';

import images from '../../images/images';
import CustomFormCheckbox from '../../components/common/CustomFormCheckbox';
import Dialog from '../../components/common/Dialog';
import CustomFormTextarea from '../../components/common/CustomFormTextarea';
import CustomFormRadio from '../../components/common/CustomFormRadio';
import Regex from '../../utils/regex';
import useReserve from '../../api/reserve-info-service';
import useRouter from '../../hooks/use-router';

const SliderDrawerMobile = ({ isFirst = true, show = true }) => {
  const [state, produce] = useImmer({
    ...location.state,
    isShow: false,
    isAgree: true,
    deliverWayRatio: true,
    agreeNewsRatio: true,
    dueDateValue: undefined,
    isCheck: false,
    loadingCheck: false,
    loadingForm: false,
    verifyCode: null,
    verifyCodeArr: null,
    rotate: null,
    fz: null,
    errorMessage: null,
    msgSource: [],
  });
  const {
    isShow,
    isAgree,
    deliverWayRatio,
    agreeNewsRatio,
    dueDateValue,
    isCheck,
    loadingCheck,
    loadingForm,
    verifyCode,
    verifyCodeArr,
    rotate,
    fz,
    errorMessage,
    msgSource,
  } = state;
  const { register, handleSubmit, setValue, triggerValidation, getValues, errors, watch } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: { day: 15, infoFrom: '' },
  });
  const { postReserveInfoCheck, postReserveInfo, getMsgSource } = useReserve();
  const { pathname } = useRouter();
  const infoSelectRef = useRef(null);
  const watchInfoFrom = watch('infoFrom');

  const hallOptions = [
    { value: 0, label: '國泰台北會館產後護理之家' },
    { value: 2, label: '國泰內湖產後護理之家' },
    { value: 3, label: '國泰竹教產後護理之家' },
    { value: 1, label: '國泰台南產後護理之家' },
  ];
  const dayOptions = [
    { value: 15, label: '15天' },
    { value: 20, label: '20天' },
    { value: 30, label: '30天' },
  ];
  const phoneValidate = (value) => {
    if (!Regex.MOBILE.test(value)) {
      return '行動電話格式不正確';
    }
  };
  const emailValidate = (value) => {
    if (!value.includes('@')) {
      return '電子郵件格式不正確';
    }
  };
  const agreeValidate = () => {
    if (!isAgree) {
      return '該欄位必填';
    }
  };
  const verifyValidate = (value) => {
    if (value != verifyCode) {
      return '驗證碼不符，請重新輸入';
    }
  };

  useEffect(() => {
    produce((draft) => {
      draft.rotate = getRandom(75, -75, 4);
      draft.fz = getRandom(16, 20, 4);
      draft.verifyCodeArr = getRandom(109, 48, 4);
    });
  }, []);
  useEffect(() => {
    if (verifyCodeArr != null) {
      let codeString = '';
      verifyCodeArr.map((v) => (codeString += String.fromCharCode(v > 57 && v < 84 ? v + 7 : v < 57 ? v : v + 13)));
      produce((draft) => {
        draft.verifyCode = codeString;
      });
      canvas();
    }
  }, [verifyCodeArr]);

  const getRandom = (max, min, num) => {
    const asciiNum = ~~(Math.random() * (max - min + 1) + min);
    if (!Boolean(num)) {
      return asciiNum;
    }
    const arr = [];
    for (let i = 0; i < num; i++) {
      arr.push(getRandom(max, min));
    }

    return arr;
  };
  const canvas = () => {
    const canvas = document.getElementById('bgi');
    let ctx = canvas.getContext('2d');
    canvas.height = canvas.height;
    // ctx.clearRect(0, 0, canvas.width(), canvas.height())
    ctx.strokeStyle = `rgb(${getRandom(100, 10, 3).toString()})`;
    for (let i = 0; i < 7; i++) {
      ctx.lineTo(getRandom(200, 0), getRandom(200, 10));
      ctx.moveTo(getRandom(200, 0), getRandom(200, 0));
      ctx.stroke();
    }
  };
  const VCode = () => (
    <div className="vcodewrap">
      <canvas id="bgi" width="200" height="200"></canvas>
      {verifyCodeArr?.map((v, i) => (
        <div
          key={i}
          className="itemStr"
          style={{
            transform: `rotate(${rotate[i]}deg)`,
            fontSize: `${fz[i]}px`,
            fontWeight: 800,
            color: 'black',
          }}
        >
          {String.fromCharCode(v > 57 && v < 84 ? v + 7 : v < 57 ? v : v + 13)}
        </div>
      ))}
      <i
        className="fa"
        onClick={() => {
          produce((draft) => {
            draft.rotate = getRandom(75, -75, 4);
            draft.fz = getRandom(16, 20, 4);
            draft.verifyCodeArr = getRandom(109, 48, 4);
          });
        }}
        style={{ fontSize: 18, position: 'absolute', right: -28, top: 9, color: '#C5C1BA', cursor: 'pointer' }}
      >
        &#xf021;
      </i>
    </div>
  );

  const handleDateChange = (value) => {
    produce((draft) => void (draft.dueDateValue = moment(value).format('YYYY/MM/DD')));
  };

  const handleCheck = async () => {
    const result = await triggerValidation(['name', 'branch', 'cellPhone', 'isAgree']);

    let data = {
      name: getValues().name,
      branch: parseInt(getValues().branch),
      cellPhone: getValues().cellPhone,
    };
    if (result) {
      produce((draft) => {
        draft.isCheck = true;
        draft.loadingCheck = true;
      });
      const { status, message } = await postReserveInfoCheck(data);
      // if (status == "0") {
      //   produce((draft) => void (draft.successMessage = message));
      //   produce((draft) => void (draft.success = true));
      // }
      produce((draft) => void (draft.loadingCheck = false));
    }
  };

  const handleMsgSource = async (data) => {
    if (data !== 0 && !data) return;

    const { status, message, result } = await getMsgSource(data);
    if (!!result) {
      produce((draft) => void (draft.msgSource = _.map(result, (source) => ({ value: source, label: source }))));
    }
  };

  const onSubmit = async (data) => {
    let variable = {
      ..._.omit(data, ['agreeNews', 'branch', 'day', 'deliverWay', 'verify', 'isAgree']),
      agreeNews: data.agreeNews == 'true' ? true : false,
      branch: parseInt(data.branch),
      day: parseInt(data.day),
      deliverWay: data.deliverWay == 'true' ? '自然產' : '剖腹產',
      agreePI: isAgree,
      infoFrom: data.infoFrom === '其他' ? `${data.infoFrom} - ${data.otherFrom}` : data.infoFrom,
    };
    try {
      fbq('track', 'Lead');
    } catch (e) { console.log(e) }
    try {
      gtag('event', 'conversion', { 'send_to': 'AW-10864886987/_Y5MCJLxrZwaEMuJ5Lwo' });
    } catch (e) { console.log(e) }
    produce((draft) => void (draft.loadingForm = true));
    const { status, message } = await postReserveInfo(variable);
    if (status == '0') {
      produce((draft) => void (draft.errorMessage = message));
    } else {
      produce((draft) => void (draft.errorMessage = '謝謝您，預約資料已送出，客服人員將會盡速與您聯繫。'));
    }
    produce((draft) => void (draft.loadingForm = false));
  };
  return (
    <div
      style={{
        background: '#F7F4EE',
        position: 'fixed',
        top: isShow ? 111 : 10,
        bottom: isShow ? 60 : 190,
        right: 20,
        width: isShow ? '90%' : '',
        zIndex: 200,
        transform: isShow ? 'translateX(0)' : 'translateX(106%)',
        transition: isShow && 'transform 0.3s ease-out',
        display: show ? '' : 'none',
      }}
      css={styles}
    >
      {isShow && (
        <MetaTags>
          <title>預約參觀｜內湖、松山、台南、新竹月子中心推薦｜國泰產後護理之家</title>
          <meta
            name="description"
            content="國泰產後護理之家在台北市松山、內湖、新竹市北區及台南市中西區皆有據點，提供優質坐月子環境與舒適房型，享受從孕期到產後的明星般尊榮待遇，立即預約參觀。"
          />
          <meta name="keywords" content="國泰,國泰產後,國泰產後護理之家,產後護理之家,產後護理,坐月子,月子中心" />
        </MetaTags>
      )}
      {!isShow && (
        <div
          style={{
            height: isFirst ? 151 : 50,
            width: 51,
            backgroundColor: isShow ? '#52B54F' : 'rgba(82,181,79,0.7)',
            marginLeft: -51,
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
            cursor: 'pointer',
            alignSelf: isFirst ? 'center' : '',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 17,
          }}
          onClick={() => produce((draft) => void (draft.isShow = !isShow))}
        >
          <img width={18} height={18} src={isShow ? images.reservation_close : images.reservation_open} />
          {isFirst && (
            <span
              style={{ writingMode: 'tb-rl', letterSpacing: 6, marginTop: 5, fontSize: 16, color: '#FFFFFF', fontWeight: 500 }}
            >
              預約參觀
            </span>
          )}
        </div>
      )}
      <div
        style={{ position: 'absolute', right: 15, top: 15, zIndex: 200, cursor: 'pointer' }}
        onClick={() => produce((draft) => void (draft.isShow = !isShow))}
      >
        <img className="d-table mx-auto mb-3" src={images.close} alt="close" />
      </div>

      <ReactShadowScroll
        isShadow={false}
        scrollColor="#52B54F"
        style={{
          height: '100%',
        }}
        styleSubcontainer={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          marginTop: 40,
          height: 'calc(100% - 60px)',
          overflowX: 'hidden',
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2 style={{ fontSize: 36, color: '#5E5E5E' }}>基本資料</h2>
          <span style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 500 }}>請先填寫您的基本資料。</span>

          <Row lg="12" className="my-3 px-3 ">
            <span className="text-semibold d-inline-block" style={{ color: '#C24C4C' }}>
              *
            </span>
            <span className="text-semibold d-inline-block" style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>
              媽媽姓名
            </span>
            <FormInput
              name="name"
              className="formInput"
              invalid={!!errors.name}
              innerRef={register({
                required: '該欄位必填',
                maxLength: { value: 12, message: '最多12個字元' },
              })}
              style={{ backgroundColor: '#F7F4EE' }}
              placeholder={'請填入媽媽姓名'}
            />
            <FormFeedback className="font-weight-light" valid={!errors.name}>
              {errors?.name?.message}
            </FormFeedback>
          </Row>

          <Row lg="12" className="mb-3 px-3" style={{ flexDirection: 'column' }}>
            <div>
              <span className="text-semibold d-inline-block" style={{ color: '#C24C4C' }}>
                *
              </span>
              <span className="text-semibold d-inline-block" style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>
                選擇會館
              </span>
            </div>
            <Select
              isClearable={false}
              isSearchable={false}
              placeholder={''}
              onChange={(value) => {
                setValue('branch', value?.value);
                handleMsgSource(value?.value);
                infoSelectRef.current?.select.setValue(null);
              }}
              options={hallOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !errors.branch ? '#C5C1BA' : '#c4183c',
                  backgroundColor: '#F7F4EE',
                  minHeight: 40,
                  height: 40,
                }),
                container: (provided) => ({
                  ...provided,
                  zIndex: 2,
                }),
              }}
            />
            <FormInput
              name="branch"
              invalid={!!errors.branch}
              innerRef={register({
                required: '該欄位必填',
              })}
              style={{ opacity: 0, height: 0, width: 0, padding: 0 }}
              disabled={true}
            />
            <FormFeedback className="font-weight-light" valid={!errors.branch}>
              {errors?.branch?.message}
            </FormFeedback>
          </Row>

          <Row lg="12" className="mb-3 px-3" style={{ position: 'relative' }}>
            <span className="text-semibold d-inline-block" style={{ color: '#C24C4C' }}>
              *
            </span>
            <span className="text-semibold d-inline-block" style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>
              聯絡電話(手機)
            </span>
            <FormInput
              name="cellPhone"
              className="formInput"
              invalid={!!errors.cellPhone}
              innerRef={register({
                required: '該欄位必填',
                validate: phoneValidate,
              })}
              style={{ backgroundColor: '#F7F4EE' }}
              placeholder={'請填入手機號碼'}
              onChange={() => {
                produce((draft) => {
                  draft.isCheck = false;
                });
              }}
            />
            <FormFeedback className="font-weight-light" valid={!errors.cellPhone}>
              {errors?.cellPhone?.message}
            </FormFeedback>
            <div
              style={{
                position: 'absolute',
                height: 40,
                right: 15,
                top: 24,
              }}
            >
              {/* <Button
                style={{
                  width: 62,
                  height: 40,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  backgroundColor: '#52B54F',
                  fontSize: 16,
                  padding: 0,
                }}
                theme="success"
                onClick={handleCheck}
              >
                {isCheck ? '已檢查' : '檢查'}
              </Button> */}
            </div>
          </Row>

          <Row lg="12" className="mb-3 px-3" style={{ flexDirection: 'column' }}>
            <div>
              <span className="text-semibold d-inline-block" style={{ color: '#C24C4C' }}>
                *
              </span>
              <span className="text-semibold d-inline-block" style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>
                是否同意使用您的個人資訊。
              </span>
            </div>
            <CustomFormCheckbox
              checked={isAgree}
              className="select text-left pl-4 mt-2"
              onChange={() => produce((draft) => void (draft.isAgree = !isAgree))}
            >
              同意
            </CustomFormCheckbox>

            <FormInput
              name="isAgree"
              invalid={!!errors.isAgree}
              innerRef={register({
                validate: agreeValidate,
              })}
              style={{ opacity: 0, height: 0, width: 0, padding: 0 }}
              disabled={true}
            />
            <FormFeedback className="font-weight-light" valid={!errors.isAgree}>
              {errors?.isAgree?.message}
            </FormFeedback>
          </Row>

          <Row lg="12" className="mb-3 px-3">
            <span className="text-semibold d-inline-block" style={{ color: '#C24C4C' }}>
              *
            </span>
            <span className="text-semibold d-inline-block" style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>
              Email
            </span>
            <FormInput
              name="email"
              className="formInput"
              invalid={!!errors.email}
              innerRef={register({
                required: '該欄位必填',
                validate: emailValidate,
              })}
              style={{ backgroundColor: '#F7F4EE' }}
              placeholder={'請輸入您的電子郵件'}
            />
            <FormFeedback className="font-weight-light" valid={!errors.email}>
              {errors?.email?.message}
            </FormFeedback>
          </Row>

          <Row lg="12" className="mb-3 px-3">
            <span className="text-semibold d-inline-block" style={{ color: '#C24C4C' }}>
              *
            </span>
            <span className="text-semibold d-inline-block" style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>
              預產期
            </span>
            <DatePicker
              locale="zh"
              disabledKeyboardNavigation
              dateFormat="YYYY/MM/dd"
              onChange={handleDateChange}
              customInput={
                <div style={{ padding: 0, width: '100%', borderWidth: 0, backgroundColor: '#F7F4EE' }}>
                  <FormInput
                    name="dueDate"
                    className="formInput"
                    invalid={!!errors.dueDate}
                    innerRef={register({
                      required: '該欄位必填',
                    })}
                    value={dueDateValue}
                    style={{ backgroundColor: '#F7F4EE' }}
                    placeholder={'請選擇預產期'}
                  />
                  <FormFeedback className="font-weight-light" valid={!errors.dueDate}>
                    {errors?.dueDate?.message}
                  </FormFeedback>

                  <div
                    className="ml-auto"
                    style={{
                      position: 'absolute',
                      height: 38,
                      right: 0,
                      top: 0,
                      width: 43,
                      borderLeft: `1px solid ${errors.verify ? '#C24C4C' : '#C5C1BA'} `,
                    }}
                  >
                    <img
                      width={20}
                      height={20}
                      src={images.calendar}
                      style={{
                        backgroundColor: '#F7F4EE',
                        cursor: 'pointer',
                        borderWidth: 0,
                        marginLeft: 10,
                        marginTop: 10,
                      }}
                    />
                  </div>
                </div>
              }
            />
          </Row>

          <Row lg="12" className="mb-3 px-3" style={{ flexDirection: 'column' }}>
            <div>
              <span className="text-semibold d-inline-block" style={{ color: '#C24C4C' }}>
                *
              </span>
              <span className="text-semibold d-inline-block" style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>
                期望入住天數
              </span>
            </div>
            <Select
              isClearable={false}
              isSearchable={false}
              onChange={(value) => {
                setValue('day', value?.value);
              }}
              defaultValue={dayOptions[0]}
              options={dayOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: !errors.day ? '#C5C1BA' : '#c4183c',
                  backgroundColor: '#F7F4EE',
                  minHeight: 40,
                  height: 40,
                }),
                container: (provide) => ({
                  ...provide,
                  zIndex: 2,
                }),
              }}
            />
            <FormInput
              name="day"
              invalid={!!errors.day}
              innerRef={register({
                required: '該欄位必填',
              })}
              style={{ opacity: 0, height: 0, width: 0, padding: 0 }}
              disabled={true}
            />
            <FormFeedback className="font-weight-light" valid={!errors.day}>
              {errors?.day?.message}
            </FormFeedback>
          </Row>

          <Row lg="12" className="mb-3 px-0">
            <Col>
              <span className="text-semibold d-inline-block" style={{ color: '#C24C4C' }}>
                *
              </span>
              <span
                className="text-semibold d-inline-block"
                style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400, width: '90%' }}
              >
                生產方式
              </span>
              <Row className="ml-0">
                <CustomFormRadio
                  inline
                  name="deliverWay"
                  value="true"
                  innerRef={register}
                  checked={deliverWayRatio}
                  onChange={() => produce((draft) => void (draft.deliverWayRatio = !deliverWayRatio))}
                >
                  自然產
                </CustomFormRadio>
                <CustomFormRadio
                  inline
                  name="deliverWay"
                  value="false"
                  innerRef={register}
                  checked={!deliverWayRatio}
                  onChange={() => produce((draft) => void (draft.deliverWayRatio = !deliverWayRatio))}
                >
                  剖腹產
                </CustomFormRadio>
              </Row>
            </Col>
          </Row>

          <Row lg="12" className="mb-3 px-0">
            <Col>
              <span className="text-semibold d-inline-block" style={{ color: '#C24C4C' }}>
                *
              </span>
              <span className="text-semibold d-inline-block" style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>
                參觀訊息來源
              </span>
              <Select
                isClearable={false}
                isSearchable={false}
                onChange={(target) => {
                  setValue('infoFrom', target?.value);
                }}
                ref={infoSelectRef}
                defaultValue={msgSource[0]}
                options={msgSource}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderColor: !errors.infoFrom ? '#C5C1BA' : '#c4183c',
                    backgroundColor: '#F7F4EE',
                    minHeight: 40,
                    height: 40,
                  }),
                }}
              />
              <FormInput
                name="infoFrom"
                invalid={!!errors.infoFrom}
                innerRef={register({
                  required: '該欄位必填',
                })}
                style={{ opacity: 0, height: 0, width: 0, padding: 0 }}
                disabled={true}
              />
              <FormFeedback className="font-weight-light" valid={!errors.infoFrom}>
                {errors?.infoFrom?.message}
              </FormFeedback>
            </Col>
            <Col>
              <span className="text-semibold d-inline-block" style={{ color: '#C24C4C' }}>
                *
              </span>
              <span className="text-semibold d-inline-block" style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>
                其他訊息來源
              </span>
              <FormInput
                name="otherFrom"
                className="formInput"
                invalid={!!errors.otherFrom}
                innerRef={register({
                  required: watchInfoFrom === '其他' ? '該欄位必填' : false,
                })}
                style={{ backgroundColor: '#F7F4EE' }}
                placeholder={'請填寫其他來源'}
                disabled={watchInfoFrom === '其他' ? false : true}
                maxLength="10"
              />
              <FormFeedback className="font-weight-light" valid={!errors.otherFrom}>
                {errors?.otherFrom?.message}
              </FormFeedback>
            </Col>
          </Row>

          <Row lg="12" className="mb-3 px-3" style={{ flexDirection: 'column', height: 300 }}>
            <span className="text-semibold d-inline-block" style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>
              問題簡述
            </span>
            <CustomFormTextarea
              name="description"
              maxLength={255}
              invalid={!!errors.description}
              innerRef={register()}
              errorMessage={errors?.description?.message}
              placeholder={'您若有其他問題，可填寫於此欄位'}
            />
          </Row>

          <Row lg="12" className="mb-3 px-3">
            <span className="text-semibold d-inline-block" style={{ color: '#C24C4C' }}>
              *
            </span>
            <span
              className="text-semibold d-inline-block"
              style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400, width: '90%' }}
            >
              是否同意收到最新活動資訊。
            </span>
            <Row className="ml-0">
              <CustomFormRadio
                inline
                name="agreeNews"
                value="true"
                innerRef={register}
                checked={agreeNewsRatio}
                onChange={() => produce((draft) => void (draft.agreeNewsRatio = !agreeNewsRatio))}
              >
                是
              </CustomFormRadio>
              <CustomFormRadio
                inline
                name="agreeNews"
                value="false"
                innerRef={register}
                checked={!agreeNewsRatio}
                onChange={() => produce((draft) => void (draft.agreeNewsRatio = !agreeNewsRatio))}
              >
                否
              </CustomFormRadio>
            </Row>
          </Row>

          <Row lg="12" className="mb-3 px-3" style={{ position: 'relative', width: 300 }}>
            <span className="text-semibold d-inline-block" style={{ color: '#C24C4C' }}>
              *
            </span>
            <span className="text-semibold d-inline-block" style={{ fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>
              驗證碼
            </span>
            <FormInput
              name="verify"
              className="formInput"
              invalid={!!errors.verify}
              innerRef={register({
                required: '該欄位必填',
                validate: verifyValidate,
              })}
              style={{ backgroundColor: '#F7F4EE', paddingRight: 80 }}
              placeholder={'請填入右側驗證碼'}
            />
            <FormFeedback className="font-weight-light" valid={!errors.verify}>
              {errors?.verify?.message}
            </FormFeedback>
            <div
              style={{
                position: 'absolute',
                height: 38,
                right: 18,
                top: 25,
                borderLeft: `1px solid ${errors.verify ? '#C24C4C' : '#C5C1BA'} `,
              }}
            >
              <VCode />
            </div>
          </Row>

          <Row style={{ justifyContent: 'center', marginTop: 40, marginBottom: 40 }}>
            <Button
              theme="success"
              className="my-1"
              style={{ maxHeight: 42, width: 124, fontSize: 16, backgroundColor: '#52B54F' }}
              disabled={loadingForm}
            >
              送出
            </Button>
          </Row>
        </Form>
      </ReactShadowScroll>
      <Dialog
        title="提示"
        message={errorMessage}
        isShowDialog={errorMessage != null}
        overlayAction={() =>
          produce((draft) => {
            draft.errorMessage = null;
            draft.isShow = false;
          })
        }
        confirmButtonProp={{
          action: () =>
            produce((draft) => {
              draft.errorMessage = null;
              draft.isShow = false;
            }),
        }}
        cancelButtonProp={{
          isShow: false,
        }}
      />
      {loadingForm && (
        <div className="loading-view">
          <BounceLoader size={150} color={'#52B54F'} loading={loadingForm} />
        </div>
      )}
    </div>
  );
};

export default SliderDrawerMobile;

const styles = css`
  .form-control {
    font-size: 16px;
    color: #5e5e5e;
    font-weight: 400;
  }
  .formInput::placeholder {
    color: #c5c1ba;
    font-weight: 400;
  }
  .css-1wa3eu0-placeholder {
    color: #c5c1ba;
    font-weight: 400;
    font-size: 13px;
    top: 45%;
  }
  .css-1okebmr-indicatorSeparator {
    width: 0px;
  }
  .css-1uccc91-singleValue {
    font-size: 16px;
    color: #5e5e5e;
    font-weight: 400;
  }
  .custom-radio {
    z-index:0;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #52b54f;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }

  .vcodewrap {
    user-select: none;
    width: 80px;
    /* overflow: hidden; */
    font-size: 8px;
    height: 38px;
    text-align: center;
    position: relative;
    background-color: white;
  }

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.95);
    opacity: 0.8;
    line-height: 38px;
    color: white;
    z-index: 1;
  }
  .itemStr {
    float: left;
    margin-left: 5px;
    margin-top: 5px;
  }
  #bgi {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .loading-view {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
  }
  .loading-view span {
    margin-left: calc(55% - 45px);
    margin-top: calc(40vh - 75px);
  }
`;
