import React from 'react';
import { useImmer } from 'use-immer';
import { css } from '@emotion/core';
import _ from 'lodash';
import { Row, Col } from 'shards-react';
import MetaTags from 'react-meta-tags';

import images from '../../images/images';
import { TopScrollContainer } from '../../components/custom-list/index';
import { useMediaQuery } from 'react-responsive';

const Diet = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const FiveCharDatas = [
    {
      image: images.diet_08_01,
      title: '身體組成量測',
      items: [
        '透過身體組成分析儀，幫助媽咪了解產後體內水分代謝情形、脂肪／肌肉比例，作為營養師個別化諮詢參考依據。',
        '入住後、出住前檢測對照，了解媽咪入住期間體態恢復情形。',
      ],
    },
    {
      image: images.diet_08_02,
      title: '營養諮詢',
      items: [
        '駐館營養師一對一諮詢，給予每位媽咪專屬的飲食建議。',
        '根據媽咪產後生理及復原狀況，給予適切飲食建議，幫助媽咪緩解身體不適、恢復體力與乳汁分泌。',
      ],
    },
    {
      image: images.diet_08_04,
      title: '餐點特色',
      items: [
        '自設廚房現煮現送，熱騰騰美食即時端上桌。',
        '嚴選台灣在地新鮮食材、專業營養師監製菜單，不含任何人工添加物。',
        '節慶驚喜餐點，給媽咪體驗一場營養、美味又健康的特色饗宴！',
      ],
    },
  ];

  return (
    <TopScrollContainer
      breadcrumb={[{ title: '首頁', to: '/' }, { title: '專業照護' }, { title: '營養膳食' }]}
      titleBackground={images.top_08}
      background={images.background_08}
      title={'營養膳食'}
      // titleBtn={['身體組成量測', '營養諮詢', '餐點特色']}
    >
      <MetaTags>
        <title>營養膳食｜自設廚房新鮮現製、階段營養諮詢與調理｜國泰產後護理之家</title>
        <meta
          name="description"
          content="運用身體組成量測科學基礎，提供媽媽專屬飲食照顧與營養諮詢。月子餐由駐館營養師親自調配，搭配自設廚房新鮮現煮，階段進補幫助緩解不適、恢復體力與乳汁分泌。"
        />
        <meta
          name="keywords"
          content="國泰,國泰產後,國泰產後護理之家,產後護理之家,產後護理,坐月子,月子中心,月子餐,月子中心 營養諮詢,月子中心 月子餐,產後護理之家 月子餐"
        />
      </MetaTags>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {_.map(FiveCharDatas, (data, index) => (
          <Row id={`item${index}`} key={index} className="mb-4" style={{ width: '100%', flex: 1 }}>
            <Col className="ml-2" style={{ alignSelf: 'center' }}>
              <h3 style={{ fontSize: 24, color: '#5E5E5E', fontWeight: 500 }}>{data.title}</h3>
              <ul style={{ marginLeft: -15 }}>
                {_.map(data.items, (item, index) => (
                  <li
                    key={index}
                    style={{
                      fontSize: 16,
                      color: '#5E5E5E',
                      textAlign: 'justify',
                      fontWeight: 400,
                      marginBottom: 15,
                      listStyleImage: `url(${images.tree_icon})`,
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </Col>
            <img
              src={data.image}
              style={{
                width: !isMobile ? '50%' : 'calc(100% - 20px)',
                height: 'fit-content',
                marginLeft: !isMobile ? 15 : 25,
                borderRadius: 6,
              }}
            />
          </Row>
        ))}
      </div>
    </TopScrollContainer>
  );
};

export default Diet;

const styles = css``;
