import React from 'react';
import _ from 'lodash';
import { Row, Col } from 'shards-react';
import { useMediaQuery } from 'react-responsive';
import images from '../../images/images';
import { TopScrollContainer } from '../../components/custom-list/index';
import MetaTags from 'react-meta-tags';

const Mother = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const MotherDatas = [
    ['嚴格執行感染管控、查核與門禁刷卡管制。', '衛生主管機關定期評鑑監督。', '定期紫外線消毒、每日住房清潔服務，打造安心環境。'],
    ['婦產科醫師及中醫師定期巡診，幫助媽咪了解自身復原狀況。', '24小時護理師輪班，依媽咪需求即時提供協助。'],
    [
      '專業營養師、護理師，親自傳授哺乳期營養、產後體態恢復及育嬰秘笈。',
      '輕鬆瑜珈課程，舒緩媽咪孕期和產後緊繃的身心。',
      'DIY暖心手作，親手製作送給寶寶的第一個禮物。',
    ],
  ];

  const CustomImg = ({ width, imgSrc, style }) => (
    <img
      src={imgSrc}
      style={{
        marginBottom: isMobile && 15,
        width: isMobile ? 'calc(100% - 20px)' : width,
        marginLeft: isMobile ? 25 : 15,
        height: isMobile ? 260 : 'fit-content',
        objectFit: isMobile ? 'cover' : '',
        borderRadius: 6,
        ...style,
      }}
    />
  );

  return (
    <TopScrollContainer
      breadcrumb={[{ title: '首頁', to: '/' }, { title: '專業照護' }, { title: '媽媽照護' }]}
      titleBackground={images.top_09}
      background={images.background_09}
      title={'媽媽照護'}
      // titleBtn={['環境感染管控', '醫護巡診', '媽媽教室']}
    >
      <MetaTags>
        <title>媽媽照護｜媽媽課程、環境感染管控、醫護巡診｜國泰產後護理之家</title>
        <meta name="description" content="婦產科醫師及中醫師定期巡診，護理師24小時即時協助。還有哺乳、體態恢復、育嬰秘笈、暖心手作、輕鬆瑜珈等課程，給予媽咪育兒新知並舒緩孕期和產後緊繃的身心。" />
        <meta name="keywords" content="國泰,國泰產後,國泰產後護理之家,產後護理之家,產後護理,坐月子,月子中心,月子中心 醫師巡診" />
      </MetaTags>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Row
          id="item0"
          className="mb-4"
          style={{ width: '100%', flex: 1, justifyContent: 'space-evenly', alignItems: 'center' }}
        >
          <CustomImg imgSrc={images.mother_09_01} width={'30%'} />
          <CustomImg imgSrc={images.mother_09_02} width={'30%'} />
          <Col className="ml-2" style={{ alignSelf: 'center' }}>
            <h3 style={{ fontSize: 24, color: '#5E5E5E', fontWeight: 500 }}>環境感染管控</h3>
            <ul style={{ marginLeft: -15 }}>
              {_.map(MotherDatas[0], (item, index) => (
                <li
                  key={index}
                  style={{
                    fontSize: 16,
                    color: '#5E5E5E',
                    textAlign: 'justify',
                    fontWeight: 400,
                    marginBottom: 15,
                    listStyleImage: `url(${images.tree_icon})`,
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row
          style={{
            background: '#C5C1BA',
            height: 1,
            marginRight: 0,
          }}
        />

        <Row id="item1" className="my-4" style={{ width: '100%', flex: 1, alignItems: 'center' }}>
          {isMobile && (
            <img
              src={images.mother_09_03}
              style={{
                marginBottom: 15,
                alignSelf: 'center',
                width: 'calc(100% - 20px)',
                marginLeft: 25,
                height: 260,
                objectFit: 'cover',
                borderRadius: 6,
              }}
            />
          )}
          <Col className="ml-2" style={{ alignSelf: 'center' }}>
            <h3 style={{ fontSize: 24, color: '#5E5E5E', fontWeight: 500 }}>醫護巡診</h3>
            <ul style={{ marginLeft: -15 }}>
              {_.map(MotherDatas[1], (item, index) => (
                <li
                  key={index}
                  style={{
                    fontSize: 16,
                    color: '#5E5E5E',
                    textAlign: 'justify',
                    fontWeight: 400,
                    marginBottom: 15,
                    listStyleImage: `url(${images.tree_icon})`,
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </Col>
          {!isMobile && <img src={images.mother_09_03} style={{ width: '65%', height: 'fit-content', marginLeft: 15 }} />}
        </Row>
        <Row
          style={{
            background: '#C5C1BA',
            height: 1,
            marginRight: 0,
          }}
        />

        <Row id="item2" className="my-4" style={{ width: '100%', flex: 1, alignItems: 'center' }}>
          <CustomImg imgSrc={images.mother_09_04} width={'65%'} />
          <Col className="ml-2" style={{ alignSelf: 'center' }}>
            <h3 style={{ fontSize: 24, color: '#5E5E5E', fontWeight: 500 }}>媽媽教室</h3>
            <ul style={{ marginLeft: -15 }}>
              {_.map(MotherDatas[2], (item, index) => (
                <li
                  key={index}
                  style={{
                    fontSize: 16,
                    color: '#5E5E5E',
                    textAlign: 'justify',
                    fontWeight: 400,
                    marginBottom: 15,
                    listStyleImage: `url(${images.tree_icon})`,
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row style={{ marginTop: isMobile ? 0 : 5, width: '100%', flex: 1, alignItems: 'center' }} >
          <CustomImg
            imgSrc={images.mother_09_05}
            width={`${1000 / 31}%`}
            style={{ paddingLeft: isMobile ? '' : 15, marginLeft: isMobile ? 25 : '', height: isMobile ? 260 : '' }}
          />
          <CustomImg
            imgSrc={images.mother_09_06}
            width={`${2100 / 31}%`}
            style={{ paddingLeft: isMobile ? '' : 30, marginLeft: isMobile ? 25 : '' }}
          />
        </Row>
      </div>
    </TopScrollContainer>
  );
};

export default Mother;
