import React from 'react';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import { Button, Row, Col } from 'shards-react';
import { useMediaQuery } from 'react-responsive';
import MetaTags from 'react-meta-tags';

import images from '../../images/images';
import { TopScrollContainer, RoomPopUp } from '../../components/custom-list/index';
import useRouter from '../../hooks/use-router';

const TaipeiHall = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const [state, produce] = useImmer({
    ...location.state,
    tabSelect: 0,
    detail: null,
  });
  const { tabSelect, detail } = state;
  const { push } = useRouter();
  const TaipeiPublicDatas = [
    {
      image: images.taipei_05_a_07,
      title: '接待大廳',
      items: [
        '寬闊的空間及大面積的嬰兒會客窗，提供媽咪們舒適的聊天環境並可同時兼顧看到寶貝的需求。',
        '嚴格執行感染管控、門禁管制，守護媽咪、寶寶安全。',
        '配有高效能空氣清淨機，維持會館清新空氣。'
      ],
    },
    {
      image: images.taipei_05_a_02,
      // image2: isMobile ? images.taipei_05_a_03 : images.taipei_05_a_02,
      title: '嬰兒室',
      items: [
        '大面積透明玻璃窗，24小時照護全程透明化。',
        '寶寶專屬獨立浴盆及尿布台，給予每位寶寶最乾淨、安全、獨一無二的照護空間。',
        '防火玻璃、防火門、無毒抗菌地板，高規格守護寶寶。',
        '寶寶專屬攝影鏡頭，隨時關注寶寶的即時動態。',
        '放置Aura air空氣淨化機及每週2次紫外線消毒，維護環境清潔與安全。',
      ],
    },
    {
      image: images.taipei_05_a_04,
      title: '媽媽幸福空間',
      items: [
        '藴育共享區：開啟對話與交流的放鬆空間，並定期舉辦多元化媽媽教室，由專業講師親自傳授孕期營養、產後護理秘笈，並在瑜珈老師/物理治療師的指導下伸展與舒緩。',
        'SPA & Hair Salon專屬空間：享受尊榮級SPA、美容、美髮專屬服務。',
      ],
    },
    {
      image: images.taipei_05_a_09,
      title: '多功能諮詢室',
      items: [
        '專業醫療團隊尊榮、客製化一對一諮詢服務，提供媽咪產前、產後護理和育嬰知識。',
      ],
    },
    {
      image: images.taipei_05_a_01,
      title: '大寶友善遊戲區',
      items: [
        '提供大寶專屬的友善娛樂空間，讓大寶在新生兒到來後，同樣獲得重視的感受，提供各式感覺統合玩具與豐富童書繪本，讓家長陪伴大寶娛樂之餘，透過親子活動，豐富他們的想像力和創造力，更可以建立和新生兒的情感連結，維繫平衡生活。',
      ],
    },
    {
      image: images.taipei_05_a_11,
      image2: images.taipei_05_a_10,
      title: '陪宿專屬空間',
      items: [
        '明亮舒適的採光與沙發座椅，提供爸媽閱讀報章雜誌、自在休憩，享受愜意時光的好地方。',
        '智能控制霧化玻璃功能與奶油系桌椅，讓爸爸可於此辦公或開啟會議並同時保有隱私。'
      ],
    }
  ];
  const TaipeiRoomDatas = [
    {
      image: images.taipei_05_b_01,
      title: '精品寬敞 鉑悅房',
      plain: '20 坪',
      content: '一房一廳，設計大器時尚、空間寬敞舒適、兼具隱私個人空間。',
      link: 'https://www.google.com/maps/place/%E5%9C%8B%E6%B3%B0%E5%8F%B0%E5%8C%97%E6%9C%83%E9%A4%A8%E7%94%A2%E5%BE%8C%E8%AD%B7%E7%90%86%E4%B9%8B%E5%AE%B6/@25.0534416,121.5569471,3a,90y,288.97h,89.25t/data=!3m6!1e1!3m4!1sAF1QipNK2rw0t1YSqmAGAZ0iMyc1y6ys1Mk2N_inj1QG!2e10!7i12000!8i6000!4m9!3m8!1s0x3442abeb761a80dd:0x7ac285feb49f3f77!8m2!3d25.0534024!4d121.5571126!10e5!14m1!1BCgIgARICGAI!16s%2Fg%2F12jf_zpjl?authuser=0&entry=ttu',
      imgArr: [images.taipei_room_01_01, images.taipei_room_01_02, images.taipei_room_01_03, images.taipei_room_01_04, images.taipei_room_01_05],
      itemArr: [
        'King Size 電動床',
        'Panasonic 55吋 4K連網液晶電視',
        '吸乳器',
        '奶瓶消毒鍋',
        'Avent 溫奶器',
        'Nestle 雀巢膠囊咖啡機',
        'tokuyo 電動按摩椅',
        'TOTO頂級衛浴設備(免治馬桶)',
        'gunite 寶寶懶骨頭包覆機能親子互動窩',
        'Dyson 三合一涼暖智慧空氣清淨機',
        'Dyson Supersonic吹風機組',
        '浴室乾濕分離',
        '玄關隱私拉門',
        'Marshall 藍芽音響',
        '雙層冰箱',
        '保險箱',
        '臥榻沙發區',
      ],
    },
    {
      image: images.taipei_05_b_02,
      title: '高雅明亮 和悅房',
      plain: '12 坪',
      content: '細膩木紋設計，窗景觀山寬闊，打造舒適、舒壓自在環境。',
      link: 'https://www.google.com/maps/place/%E5%9C%8B%E6%B3%B0%E5%8F%B0%E5%8C%97%E6%9C%83%E9%A4%A8%E7%94%A2%E5%BE%8C%E8%AD%B7%E7%90%86%E4%B9%8B%E5%AE%B6/@25.0534268,121.556979,3a,90y,200.52h,89.46t/data=!3m6!1e1!3m4!1sAF1QipPTvarO5Qc4guHCKEanWc-_q2E6qPUeyHj5hIhO!2e10!7i12000!8i6000!4m9!3m8!1s0x3442abeb761a80dd:0x7ac285feb49f3f77!8m2!3d25.0534024!4d121.5571126!10e5!14m1!1BCgIgARICGAI!16s%2Fg%2F12jf_zpjl?authuser=0&entry=ttu',
      imgArr: [images.taipei_room_02_01, images.taipei_room_02_02, images.taipei_room_02_03, images.taipei_room_02_04],
      itemArr: [
        '斯林百蘭 Queen Size 床組 ',
        'Panasonic 55吋 4K連網液晶電視',
        'Avent 吸乳器',
        'Avent 溫奶器 ',
        '小獅王奶瓶消毒鍋',
        'Nestle 雀巢膠囊咖啡機 ',
        'tokuyo 電動按摩椅 ',
        'Red Castle 子宮睡窩 ',
        'Dyson 三合一涼暖智慧空氣清淨機',
        'Dyson Supersonic吹風機組',
        'TOTO頂級衛浴設備(免治馬桶)  ',
        '浴室乾濕分離 ',
        '玄關隱私拉門 ',
        'Marshall 藍芽音響',
        '雙層冰箱',
        '保險箱',
        '臥榻沙發區',
      ],
    },
    {
      image: images.taipei_05_b_02b,
      title: '採光透亮 和悅房B',
      plain: '12 坪',
      content: '細膩木紋設計，窗外愜意街景，打造舒適、舒壓自在環境。',
      link: 'https://www.google.com/maps/place/%E5%9C%8B%E6%B3%B0%E5%8F%B0%E5%8C%97%E6%9C%83%E9%A4%A8%E7%94%A2%E5%BE%8C%E8%AD%B7%E7%90%86%E4%B9%8B%E5%AE%B6/@25.0534933,121.5569478,3a,90y,322.39h,80.12t/data=!3m6!1e1!3m4!1sAF1QipPhhr39A7_AennUeQJE8c50f7zVOjWieCxi_Jub!2e10!7i12000!8i6000!4m9!3m8!1s0x3442abeb761a80dd:0x7ac285feb49f3f77!8m2!3d25.0534024!4d121.5571126!10e5!14m1!1BCgIgARICGAI!16s%2Fg%2F12jf_zpjl?authuser=0&entry=ttu',
      imgArr: [images.taipei_room_02b_01, images.taipei_room_02b_02, images.taipei_room_02b_03, images.taipei_room_02b_04],
      itemArr: [
        '斯林百蘭 Queen Size 床組',
        'Panasonic 55吋 4K連網液晶電視',
        'Avent 吸乳器',
        'Avent 溫奶器 ',
        '小獅王奶瓶消毒鍋',
        'Nestle 雀巢膠囊咖啡機 ',
        'tokuyo 電動按摩椅 ',
        'Red Castle 子宮睡窩 ',
        'Dyson 三合一涼暖智慧空氣清淨機',
        'Dyson Supersonic吹風機組',
        'TOTO頂級衛浴設備(免治馬桶) ',
        '浴室乾濕分離 ',
        '玄關隱私拉門 ',
        'Marshall 藍芽音響',
        '雙層冰箱',
        '保險箱',
        '臥榻沙發區',
      ],
    },
  ];
  const TaipeiConnectionDatas = [
    {
      title: `電${'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'}話`,
      content: '(02) 2748 5111',
    },
    {
      title: `地${'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'}址`,
      content: '台北市松山區健康路156號7樓',
    },
    {
      title: '停車資訊',
      content: '本大樓地下室備有停車位，入住期間可享停車優惠。',
    },
  ];

  const CustomImg = ({ width, imgSrc, style }) => (
    <img
      loading="lazy"
      src={imgSrc}
      style={{
        marginBottom: isMobile && 15,
        width: isMobile ? 'calc(100% - 20px)' : width,
        marginLeft: isMobile ? 25 : 15,
        height: 'fit-content',
        objectFit: isMobile ? 'cover' : '',
        borderRadius: 6,
        ...style,
      }}
    />
  );

  const CustomSpan = ({ title, content, key }) => (
    <div style={{ display: 'flex' }} key={key}>
      <span style={{ fontSize: 16, whiteSpace: 'nowrap', color: '#5E5E5E', fontWeight: 400 }}>{title}</span>
      <span style={{ marginLeft: 10, marginBottom: 15, fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>{content}</span>
    </div>
  );

  return (
    <TopScrollContainer
      breadcrumb={[{ title: '首頁', to: '/' }, { title: '台北' }]}
      titleBackground={images.top_05_a}
      background={images.background_05_a}
      title={isMobile ? '台北' : '國泰台北會館產後護理之家'}
      titleTab={['公共區域', '房型介紹', '聯絡方式']}
      // titleBtn={tabSelect == 0 ? ['遊戲區', '嬰兒室', '媽媽空間', '接待大廳', '諮詢室'] : null}
      handleChange={(index) => produce((draft) => void (draft.tabSelect = index))}
    // isShowBtnMobile={true}
    // isShowLineMobile={true}
    >
      <MetaTags>
        <title>台北會館房型與服務介紹｜松山區產後護理之家推薦｜國泰產後護理之家</title>
        <meta name="description" content="國泰台北會館產後護理之家，位於台北市松山區，專業醫護陣容定期巡診，24小時全護理師照護，專屬營養師協助體態恢復，坐月子明星規格尊榮待遇，立即預約！" />
        <meta
          name="keywords"
          content="產後護理之家,產後護理,國泰台北會館產後護理之家,國泰產後護理之家台北會館,台北 產後護理之家,松山區 月子中心,台北 月子中心,產後護理之家 推薦,月子中心 推薦"
        />
      </MetaTags>
      {tabSelect == 0 && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {_.map(TaipeiPublicDatas, (data, index) => {
              return (
                <div key={index}>
                  {index == 0 && !isMobile && (
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ marginRight: 15 }}>嚴格把關 用心守護</span>
                      <span style={{ fontWeight: 400 }}>採用符合法規之綠建材，更定期檢驗館內空氣品質，守護媽咪、寶寶健康</span>
                    </div>
                  )}
                  <Row
                    id={`item${index}`}
                    className={index == 0 && isMobile ? '' : isMobile ? 'mt-4' : 'mt-4 mb-4'}
                    style={{ width: '100%', flex: 1, flexDirection: index % 2 == 1 ? 'row-reverse' : 'row' }}
                  >

                    {<img
                      loading="lazy"
                      src={data.image}
                      style={{
                        width: index == 2 || index == 5 ? (isMobile ? '100%' : '30%') : isMobile ? 'calc(100% - 20px)' : '65%',
                        height: isMobile ? 260 : 'fit-content',
                        marginLeft: isMobile ? 25 : 15,
                        objectFit: isMobile ? 'cover' : '',
                        borderRadius: 6,
                        marginBottom: isMobile ? 20 : '',
                      }} />}
                    {index == 5 && (
                      <img
                        loading="lazy"
                        src={data?.image2}
                        style={{
                          width: isMobile ? '100%' : '30%',
                          height: isMobile ? 260 : 'fit-content',
                          marginLeft: isMobile ? 25 : 15,
                          borderRadius: 6,
                          marginBottom: isMobile ? 20 : '',
                        }}
                      />
                    )}
                    <Col className="ml-2">
                      <h4>{data.title}</h4>
                      <ul style={{ marginLeft: -15 }}>
                        {_.map(data.items, (item, index) => (
                          <li
                            key={index}
                            style={{
                              fontSize: 16,
                              color: '#5E5E5E',
                              textAlign: 'justify',
                              fontWeight: 400,
                              marginBottom: 15,
                              listStyleImage: `url(${images.tree_icon})`,
                            }}
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>

                  {index == 2 && (
                    <Row style={{ width: '100%', flex: 1 }}>
                      <img
                        loading="lazy"
                        src={images.taipei_05_a_05}
                        style={{
                          width: isMobile ? 'calc(100% - 20px)' : `${200 / 3}%`,
                          paddingLeft: isMobile ? '' : 15,
                          marginLeft: isMobile ? 25 : '',
                          height: isMobile ? 260 : 'fit-content',
                          objectFit: isMobile ? 'cover' : '',
                          borderRadius: 6,
                          marginBottom: isMobile ? 20 : '',
                        }} />
                      <img
                        loading="lazy"
                        src={images.taipei_05_a_06}
                        style={{
                          width: isMobile ? 'calc(100% - 20px)' : `${100 / 3}%`,
                          paddingLeft: isMobile ? '' : 30,
                          marginLeft: isMobile ? 25 : '',
                          height: isMobile ? 260 : '',
                          objectFit: isMobile ? 'cover' : '',
                          borderRadius: 6,
                          marginBottom: isMobile ? 20 : '',
                        }} />
                    </Row>
                  )}

                  {index != TaipeiPublicDatas.length - 1 && (
                    <Row
                      style={{
                        marginTop: isMobile ? '' : 19,
                        background: '#C5C1BA',
                        height: 1,
                        marginRight: 0,
                      }} />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {tabSelect == 1 && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {_.map(TaipeiRoomDatas, (data, index) => (
              <div key={index}>
                <Row id={`item${index}`} className={index == 0 && isMobile ? '' : isMobile ? "mt-4" : "my-4"} style={{ width: '100%', flex: 1 }}>
                  <CustomImg imgSrc={data.image} width={'65%'} />
                  <Col className="ml-2" style={{ alignSelf: 'center' }}>
                    <h4>{data.title}</h4>

                    <div style={{ display: 'flex' }}>
                      <img className="mr-1 lazy" src={images.square_feet} height={22} width={22} />
                      <span style={{ display: 'flex', marginBottom: 10 }}>{data?.plain}</span>
                    </div>

                    <span
                      style={{
                        fontSize: 16,
                        color: '#5E5E5E',
                        textAlign: 'justify',
                        fontWeight: 400,
                      }}
                    >
                      {data?.content}
                    </span>

                    <div style={{ display: 'flex', marginTop: 15, width: '100%', justifyContent: isMobile ? 'center' : '' }}>
                      <Button
                        theme="success"
                        className="my-1"
                        style={{
                          maxHeight: 42,
                          width: 100,
                          fontSize: 16,
                          marginRight: 15,
                          backgroundColor: 'rgba(82,181,79,1)',
                        }}
                        onClick={() => produce((draft) => void (draft.detail = data))}
                      >
                        查看詳細
                      </Button>
                      <Button
                        theme="success"
                        className="my-1"
                        style={{
                          maxHeight: 42,
                          width: 100,
                          fontSize: 16,
                          backgroundColor: 'transparent',
                          color: 'rgba(82,181,79,1)',
                        }}
                        onClick={() => window.open(data?.link, '_blank')}
                      >
                        環景預覽
                      </Button>
                    </div>
                  </Col>
                </Row>

                {index != TaipeiPublicDatas.length - 1 && (
                  <Row
                    style={{
                      marginTop: 19,
                      background: '#C5C1BA',
                      height: 1,
                      marginRight: 0,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {tabSelect == 2 && (
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : '',
            marginTop: 15,
            paddingLeft: isMobile ? 10 : '',
            paddingRight: isMobile ? 10 : '',
          }}
        >
          <div style={{ width: isMobile ? '100%' : '85%', height: isMobile ? 250 : '50vh', position: 'relative' }}>
            <iframe
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyASdP6rUVNZ5zvDVUJy86BP_EToxexL_Kc&q=%E5%9C%8B%E6%B3%B0%E5%8F%B0%E5%8C%97%E6%9C%83%E9%A4%A8%E7%94%A2%E5%BE%8C%E8%AD%B7%E7%90%86%E4%B9%8B%E5%AE%B6&language=zh-tw"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
          <div style={{ marginLeft: isMobile ? '' : 30, marginTop: 20, width: isMobile ? '100%' : '40%' }}>
            <h2 style={{ fontSize: 24, color: '#5E5E5E', fontWeight: 500 }}>聯絡方式</h2>
            {_.map(TaipeiConnectionDatas, (item, index) => (
              <CustomSpan key={index} title={item.title} content={item.content} />
            ))}
          </div>
        </div>
      )}
      {detail && (
        <RoomPopUp
          isShowDialog={!!detail}
          overlayAction={() => {
            produce((draft) => void (draft.detail = null));
            push('/taipei-hall');
          }}
          detail={detail}
        />
      )}
    </TopScrollContainer>
  );
};

export default TaipeiHall;
