import React from 'react';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import { Button, Row, Col } from 'shards-react';
import { useMediaQuery } from 'react-responsive';
import MetaTags from 'react-meta-tags';

import images from '../../images/images';
import { TopScrollContainer, RoomPopUp } from '../../components/custom-list/index';
import useRouter from '../../hooks/use-router';

const HsinchuHall = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const [state, produce] = useImmer({
    ...location.state,
    tabSelect: 0,
    detail: null,
  });
  const { tabSelect, detail } = state;
  const { push } = useRouter();
  const HsinchuPublicDatas = [
    {
      image: images.hsinchu_14_a_07,
      title: '接待大廳',
      items: [
        '嚴格執行感染管控、樓層管控、門禁管制，守護媽咪、寶寶安全。',
        '全館採用高感控安全設置，即時監控空氣品質。',
        '配有高效能空氣清淨機，維持會館清新空氣。',
      ],
    },
    {
      image: images.hsinchu_14_a_03,
      title: '嬰兒室',
      items: [
        '大面積透明玻璃窗，24小時照護全程透明化、雙嬰兒室分區、雙獨立隔離/觀察室，感控升級。',
        '防火玻璃、防火門、無毒抗菌地板，高規格守護寶寶。',
        '寶寶專屬攝影鏡頭，隨時關注寶寶的即時動態。',
        '每週2次紫外線消毒，維護環境清潔與安全。',
      ],
    },
    {
      image: images.hsinchu_14_a_05,
      title: '媽媽幸福空間',
      items: [
        '媽媽教室：開啟對話與交流的放鬆空間，並定期舉辦多元化媽媽教室，由三師親自傳授孕期營養、產後護理秘笈，並在物理治療師的指導下伸展與舒緩，客服帶領下進行身心靈療癒手作課。',
        'SPA & Hair Salon專屬空間：享受尊榮級SPA、美髮專屬服務。',
      ],
    },
    {
      image: images.hsinchu_14_a_08,
      title: '多功能諮詢室',
      items: [
        '資深護理人員，協助解決各種母嬰照顧疑問，面對緊急狀況快速提供應變措施，成為媽咪們堅強的後盾。',
        '身體組成測量，科學化數據評估產後媽咪生理狀況，搭配駐館營養師一對一諮詢，給予營養建議。',
      ],
    },
    {
      image: isMobile ? images.hsinchu_14_a_02 : images.hsinchu_14_a_01,
      image2: isMobile ? images.hsinchu_14_a_01 : images.hsinchu_14_a_02,
      title: '陪宿專屬空間',
      items: [
        '大螢幕電視提供完美視野，讓爸爸自在休憩的充電處所。',
        '提供大寶專屬的友善娛樂空間，讓大寶在新生兒到來後，同樣獲得重視的感受，提供各式感覺統合玩具與豐富童書繪本，讓家長陪伴大寶娛樂之餘，透過親子活動，豐富他們的想像力和創造力，更可以建立和新生兒的情感連結，維繫平衡生活。',
      ],
    },
  ];
  const HsinchuRoomDatas = [
    {
      image: images.hsinchu_14_b_01,
      title: '恬雅簡約 和樂房',
      plain: '8 坪',
      content: '大面落地窗，延攬絕美光景，打造舒適放鬆的愜意環境。',
      link: 'https://reurl.cc/Rr7LA6',
      imgArr: [images.hsinchu_room_01_01, images.hsinchu_room_01_02,
      images.hsinchu_room_01_03, images.hsinchu_room_01_04, images.hsinchu_room_01_05],
      itemArr: [
        '德泰訂製款Queen size床組',
        'Panasonic 55吋 4K連網液晶電視',
        '吸乳器',
        '奶瓶消毒鍋',
        'TOTO頂級衛浴設備(免治馬桶)',
        '浴室乾濕分離 ',
        '玄關隱私拉門 ',
        '衛浴暖風機 ',
        'TOTO頂級衛浴設備(免治馬桶)',
        'Marshall 藍芽音響',
        '雙層冰箱',
        '保險箱',
        '臥榻沙發區',
      ],
    },
  ];
  const HsinchuConnectionDatas = [
    {
      title: `電${'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'}話`,
      content: '(03) 515 2669',
    },
    {
      title: `地${'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'}址`,
      content: '新竹市北區武陵路189號6、7樓 (國泰新竹健康園區)',
    },
    {
      title: '停車資訊',
      content: '本園區附有收費停車場',
    },
  ];

  const CustomImg = ({ width, imgSrc, style }) => (
    <img
      loading="lazy"
      src={imgSrc}
      style={{
        marginBottom: isMobile && 15,
        width: isMobile ? 'calc(100% - 20px)' : width,
        marginLeft: isMobile ? 25 : 15,
        height: 'fit-content',
        objectFit: isMobile ? 'cover' : '',
        borderRadius: 6,
        ...style,
      }}
    />
  );

  const CustomSpan = ({ title, content, key }) => (
    <div style={{ display: 'flex' }} key={key}>
      <span style={{ fontSize: 16, whiteSpace: 'nowrap', color: '#5E5E5E', fontWeight: 400 }}>{title}</span>
      <span style={{ marginLeft: 10, marginBottom: 15, fontSize: 16, color: '#5E5E5E', fontWeight: 400 }}>{content}</span>
    </div>
  );

  return (
    <TopScrollContainer
      breadcrumb={[{ title: '首頁', to: '/' }, { title: '新竹' }]}
      titleBackground={images.top_05_a}
      background={images.background_08_a}
      title={isMobile ? '新竹' : '國泰竹教產後護理之家'}
      titleTab={['公共區域', '房型介紹', '聯絡方式']}
      // titleBtn={tabSelect == 0 ? ['遊戲區', '嬰兒室', '媽媽空間', '接待大廳', '諮詢室'] : null}
      handleChange={(index) => produce((draft) => void (draft.tabSelect = index))}
    // isShowBtnMobile={true}
    // isShowLineMobile={true}
    >
      <MetaTags>
        <title>竹教會館房型與服務介紹｜新竹北區產後護理之家推薦｜國泰產後護理之家</title>
        <meta name="description" content="國泰竹教產後護理之家，位於新竹市北區，專業醫護陣容定期巡診，24小時全護理師照護，專屬營養師協助體態恢復，坐月子明星規格尊榮待遇，立即預約！" />
        <meta
          name="keywords"
          content="國泰產後護理之家,國泰月子中心.國泰產後,國泰內湖產後護理之家,國泰產後護理之家 內湖,產後護理之家,月子中心,內湖月子中心,內湖國泰產後護理之家,台北月子中心,月子中心推薦"
        />
      </MetaTags>
      {tabSelect == 0 && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {_.map(HsinchuPublicDatas, (data, index) => (
              <div key={index}>
                {index == 0 && !isMobile && (
                  <div style={{ textAlign: 'center' }}>
                    <span style={{ marginRight: 15 }}>嚴格把關 用心守護</span>
                    <span style={{ fontWeight: 400 }}>全棟淨化導入新鮮空氣循環，即時監測確保純淨空氣品質，守護媽咪、寶寶健康</span>
                  </div>
                )}
                <Row
                  id={`item${index}`}
                  className={index == 0 && isMobile ? '' : isMobile ? 'mt-4' : 'mt-4 mb-4'}
                  style={{ width: '100%', flex: 1, flexDirection: index % 2 == 1 ? 'row-reverse' : 'row' }}
                >
                  {
                    <img
                      loading="lazy"
                      src={data.image}
                      style={{
                        width: index == 2 || index == 4 ? (isMobile ? '100%' : '30%') : isMobile ? 'calc(100% - 20px)' : '65%',
                        height: isMobile ? 260 : 'fit-content',
                        marginLeft: isMobile ? 25 : 15,
                        objectFit: isMobile ? 'cover' : '',
                        borderRadius: 6,
                        marginBottom: isMobile ? 20 : '',
                      }}
                    />
                  }
                  {index == 4 && (
                    <img
                      loading="lazy"
                      src={data?.image2}
                      style={{
                        width: isMobile ? '100%' : '30%',
                        height: 'fit-content',
                        marginLeft: isMobile ? 25 : 15,
                        marginBottom: isMobile ? 20 : '',
                      }}
                    />
                  )}
                  <Col className="ml-2">
                    <h4>{data.title}</h4>
                    <ul style={{ marginLeft: -15 }}>
                      {_.map(data.items, (item, index) => (
                        <li
                          key={index}
                          style={{
                            fontSize: 16,
                            color: '#5E5E5E',
                            textAlign: 'justify',
                            fontWeight: 400,
                            marginBottom: 15,
                            listStyleImage: `url(${images.tree_icon})`,
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>

                {index == 2 && (
                  <Row style={{ width: '100%', flex: 1 }}>
                    <img
                      loading="lazy"
                      src={images.hsinchu_14_a_04}
                      style={{
                        width: isMobile ? 'calc(100% - 20px)' : `${200 / 3}%`,
                        paddingLeft: isMobile ? '' : 15,
                        marginLeft: isMobile ? 25 : '',
                        height: isMobile ? 260 : 'fit-content',
                        objectFit: isMobile ? 'cover' : '',
                        borderRadius: 6,
                        marginBottom: isMobile ? 20 : '',
                      }}
                    />
                    <img
                      loading="lazy"
                      src={images.hsinchu_14_a_06}
                      style={{
                        width: isMobile ? 'calc(100% - 20px)' : `${100 / 3}%`,
                        paddingLeft: isMobile ? '' : 30,
                        marginLeft: isMobile ? 25 : '',
                        height: isMobile ? 260 : '',
                        objectFit: isMobile ? 'cover' : '',
                        borderRadius: 6,
                        marginBottom: isMobile ? 20 : '',
                      }}
                    />
                  </Row>
                )}
                {index != HsinchuPublicDatas.length - 1 && (
                  <Row
                    style={{
                      marginTop: isMobile ? '' : 19,
                      background: '#C5C1BA',
                      height: 1,
                      marginRight: 0,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {tabSelect == 1 && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {_.map(HsinchuRoomDatas, (data, index) => (
              <div key={index}>
                <Row id={`item${index}`} className={index == 0 && isMobile ? '' : isMobile ? "mt-4" : "my-4"} style={{ width: '100%', flex: 1 }}>
                  <CustomImg imgSrc={data.image} width={'65%'} />
                  <Col className="ml-2" style={{ alignSelf: 'center' }}>
                    <h4>{data.title}</h4>

                    <div style={{ display: 'flex' }}>
                      <img className="mr-1 lazy" src={images.square_feet} height={22} width={22} />
                      <span style={{ display: 'flex', marginBottom: 10 }}>{data?.plain}</span>
                    </div>

                    <span
                      style={{
                        fontSize: 16,
                        color: '#5E5E5E',
                        textAlign: 'justify',
                        fontWeight: 400,
                      }}
                    >
                      {data?.content}
                    </span>

                    <div style={{ display: 'flex', marginTop: 15, width: '100%', justifyContent: isMobile ? 'center' : '' }}>
                      <Button
                        theme="success"
                        className="my-1"
                        style={{
                          maxHeight: 42,
                          width: 100,
                          fontSize: 16,
                          marginRight: 15,
                          backgroundColor: 'rgba(82,181,79,1)',
                        }}
                        onClick={() => produce((draft) => void (draft.detail = data))}
                      >
                        查看詳細
                      </Button>
                      <Button
                        theme="success"
                        className="my-1"
                        style={{
                          maxHeight: 42,
                          width: 100,
                          fontSize: 16,
                          backgroundColor: 'transparent',
                          color: 'rgba(82,181,79,1)',
                        }}
                        onClick={() => window.open(data?.link, '_blank')}
                      >
                        環景預覽
                      </Button>
                    </div>
                  </Col>
                </Row>

                {index != HsinchuPublicDatas.length - 1 && (
                  <Row
                    style={{
                      marginTop: 19,
                      background: '#C5C1BA',
                      height: 1,
                      marginRight: 0,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {tabSelect == 2 && (
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : '',
            marginTop: 15,
            paddingLeft: isMobile ? 10 : '',
            paddingRight: isMobile ? 10 : '',
          }}
        >
          <div style={{ width: isMobile ? '100%' : '85%', height: isMobile ? 250 : '50vh', position: 'relative' }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14484.824104169527!2d120.9686141!3d24.822627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34683542a8b77b6b%3A0xd2a11e67bb4d5d!2z5ZyL5rOw56u55pWZ55Si5b6M6K2355CG5LmL5a62!5e0!3m2!1szh-TW!2stw!4v1710386058535!5m2!1szh-TW!2stw"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
          <div style={{ marginLeft: isMobile ? '' : 30, marginTop: 20, width: isMobile ? '100%' : '40%' }}>
            <h2 style={{ fontSize: 24, color: '#5E5E5E', fontWeight: 500 }}>聯絡方式</h2>
            {_.map(HsinchuConnectionDatas, (item, index) => (
              <CustomSpan key={index} title={item.title} content={item.content} />
            ))}
          </div>
        </div>
      )}
      {detail && (
        <RoomPopUp
          isShowDialog={!!detail}
          overlayAction={() => {
            produce((draft) => void (draft.detail = null));
            push('/hsinchu-hall');
          }}
          detail={detail}
        />
      )}
    </TopScrollContainer>
  );
};

export default HsinchuHall;
