import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'shards-react';
import { css } from '@emotion/core';
import ReactShadowScroll from 'react-shadow-scroll';
import parse from 'html-react-parser';
import { EmailShareButton, FacebookShareButton, LineShareButton, FacebookMessengerShareButton } from 'react-share';

import images from '../../images/images';

const ListPopUp = ({ overlayAction, isShowDialog = false, detail: { title, content, createdAt, type, id, currentPage, publishedOn } }) => {
  const [page, setPage] = useState(null);
  const pageArr = ["MomBabyInfos", "MomClassrooms", "WarmRecords"]
  const [url, setUrl] = useState(window.location.origin);
  useEffect(() => {
    document.querySelectorAll('oembed[url]').forEach((element) => {
      // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
      // to discover the media.
      const anchor = document.createElement('a');

      anchor.setAttribute('href', element.getAttribute('url'));
      anchor.className = 'embedly-card';

      element.appendChild(anchor);
    });
  }, [content]);

  //首頁強制導向
  useEffect(() => {
    type === undefined
    ? setUrl(`${url}/news/${id}`)
    : setUrl(`${url}/${pageArr[type]}/${id}`)
  }, [])

  return (
    <div css={stylesModal}>
      <Modal
        css={styles}
        centered
        open={isShowDialog}
        toggle={() => overlayAction && overlayAction()}
        backdropClassName="backdropBackground"
      >
        <ModalBody>
          <Col>
            <Row>
              <label className="modal-title">{title}</label>
              <span className="btn" onClick={() => overlayAction && overlayAction()}>
                <img className="d-table mx-auto mb-3" src={images.close} alt="close" />
              </span>
            </Row>
            <Row className="share-row">
              <label className="create-date">{publishedOn}</label>
              <div>
                <FacebookShareButton url={url} quote={title}>
                  <img src={images.share_01} />
                </FacebookShareButton>
                <LineShareButton url={url} title={title}>
                  <img src={images.share_02} />
                </LineShareButton>
                <FacebookMessengerShareButton
                  url={`${url}`}
                  appId={process.env.REACT_APP_FACEBOOK_ID}
                  onClick={() => {
                    window.location.href =
                      'fb-messenger://share?link=' +
                      encodeURIComponent(url) +
                      '&app_id=' +
                      encodeURIComponent(process.env.REACT_APP_FACEBOOK_ID);
                  }}
                >
                  <img src={images.share_03} />
                </FacebookMessengerShareButton>
                {/* <EmailShareButton
                  url={url}
                  subject={title}
                  // body={content}
                  onClick={() => {}}
                  openShareDialogOnClick
                  separator="<br/>"
                >
                  <img src={images.share_04} />
                </EmailShareButton> */}
              </div>
            </Row>
            <Row style={{ background: '#C5C1BA', height: 1, marginRight: 25 }} />
            <Row>
              <ReactShadowScroll
                isShadow={false}
                scrollColor="#52B54F"
                style={{
                  height: 'calc(50vh)',
                  marginBottom: 20,
                  marginTop: 20,
                  padding: 0,
                  marginLeft: 0,
                  marginRight: 0,
                  maxWidth: '100%',
                }}
              >
                <div className="ck-content">{parse(content)}</div>
              </ReactShadowScroll>
            </Row>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ListPopUp;

const stylesModal = css`
  .modal {
    z-index: 3000;
  }
`;
const styles = css`
  min-width: 70%;

  .element {
    width: fit-content;
    background: white;
    width: 100%;
    max-width: calc(100vw - 60px);
    margin-bottom: 40px;
  }
  .element img {
    width: 100%;
  }

  .modal-dialog {
    margin: auto;
  }
  .modal-header {
    border: none;
    font-family: PingFangTC-Medium;
    font-size: 13px;
    color: #505050;
    text-align: justify;
  }
  .modal-content {
    margin-top: 40px;
    max-height: calc(100vh - 80px);
    background: #f7f4ee;
    border-radius: 6px;
  }
  span.btn {
    position: absolute;
    top: -12px;
    right: -20px;
  }
  span.btn img {
    width: 15px;
  }
  .modal-title {
    font-family: PingFangTC-Medium;
    font-size: 24px;
    color: #5e5e5e;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .create-date {
    font-family: PingFangTC-Regular;
    font-size: 14px;
    color: #5e5e5e;
    text-align: justify;
    font-weight: 400;
  }
  .share-row {
    justify-content: space-between;
    padding-right: 25px;
  }
  .share-row img {
    width: 24px;
    height: 24px;
    margin-right: 15px;
    cursor: pointer;
  }
  .subcontainer {
    padding-right: 30px !important;
  }

  strong {
    font-weight:bolder;
  }
`;
